import React from "react"
import Helmet from "react-helmet"
import Row from "react-bootstrap/Row"
import Container from "react-bootstrap/Container"
import Col from "react-bootstrap/Col"
import Navbar from "../components/navbar"
import Layout from "../components/layout"
import "./software.scss"
import SEO from "../components/seo"
import { Link } from "gatsby"
import ListGroup from 'react-bootstrap/ListGroup'

const PrepareGroupFourPage = () => (
    <Layout>
        <Helmet>
            <meta
                name="viewport"
                content="width=device-width, initial-scale=1, shrink-to-fit=no, user-scalable=no"
            />
        </Helmet>
        <SEO title="4 группа - билеты для подготовки к экзамену" />

        <Navbar />
        <section className="quizzes-section-telis">
            <Container fluid>
                <Row>
                    <Col
                        xl={12}
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        className="align-self-center my-5 pt-5"
                    >
                        <h1 style={{
                            color: "var(--textNormal)",
                            wordBreak: "break-all",
                            transition: "color 0.2s ease-out, background 0.2s ease-out",
                        }} className="font-weight-bold text-center mb-0">
                            Билеты для подготовки для 4 группы
        </h1>
                        <h2 className="pt-5 w-90 text-center mx-auto" style={{
                            color: "var(--textNormal)",
                            transition: "color 0.2s ease-out, background 0.2s ease-out",
                            maxWidth: "1140px",
                        }}>В данном разделе собраны билеты для подготовки к экзамену для 4 группы. Вы можете выбрать необходимое количество тестов для прохождения, прежде чем перейти к экзамену.</h2>
                        <Container>
                            
                            <h2 className="text-center mt-5">Билеты</h2>
                            <div className="d-grid text-center my-2">
                            <a className="p-3 m-3 font-weight-bold" href="https://forms.gle/dxuooWswEcwXCKGw9" target="_blank"><div className="border border-info py-4 my-3">Билет 1</div></a>
                            <a className="p-3 m-3 font-weight-bold" href="https://forms.gle/oZJbQnsj4iAe1qu27" target="_blank"><div className="border border-info py-4 my-3">Билет 2</div></a>
                            <a className="p-3 m-3 font-weight-bold" href="https://forms.gle/pTYutf4ZUzZbktfe6" target="_blank"><div className="border border-info py-4 my-3">Билет 3</div></a>
                            <a className="p-3 m-3 font-weight-bold" href="https://forms.gle/U428rVbvkwbqDXjo6" target="_blank"><div className="border border-info py-4 my-3">Билет 4</div></a>
                            <a className="p-3 m-3 font-weight-bold" href="https://forms.gle/zuXFQJfHTwL95sZU6" target="_blank"><div className="border border-info py-4 my-3">Билет 5</div></a>
                            <a className="p-3 m-3 font-weight-bold" href="https://forms.gle/WesojqdsRmRftsiQ9" target="_blank"><div className="border border-info py-4 my-3">Билет 6</div></a>
                            <a className="p-3 m-3 font-weight-bold" href="https://forms.gle/yMdz4UoF23eos7PT9" target="_blank"><div className="border border-info py-4 my-3">Билет 7</div></a>
                            <a className="p-3 m-3 font-weight-bold" href="https://forms.gle/Bu3cNuBrqKhBPuEt6" target="_blank"><div className="border border-info py-4 my-3">Билет 8</div></a>
                            <a className="p-3 m-3 font-weight-bold" href="https://forms.gle/maBjgX3y91tTB9Mw5" target="_blank"><div className="border border-info py-4 my-3">Билет 9</div></a>
                            <a className="p-3 m-3 font-weight-bold" href="https://forms.gle/XBv2BMJ75wDwpHC17" target="_blank"><div className="border border-info py-4 my-3">Билет 10</div></a>
                            <a className="p-3 m-3 font-weight-bold" href="https://forms.gle/h5M5HiabCFtmmiNL7" target="_blank"><div className="border border-info py-4 my-3">Билет 11</div></a>
                            <a className="p-3 m-3 font-weight-bold" href="https://forms.gle/eegnyLfRRtFBquks8" target="_blank"><div className="border border-info py-4 my-3">Билет 12</div></a>
                            <a className="p-3 m-3 font-weight-bold" href="https://forms.gle/tAgitRCPMXMBN3db7" target="_blank"><div className="border border-info py-4 my-3">Билет 13</div></a>
                            <a className="p-3 m-3 font-weight-bold" href="https://forms.gle/mfrvuwXc733jzTyHA" target="_blank"><div className="border border-info py-4 my-3">Билет 14</div></a>
                            <a className="p-3 m-3 font-weight-bold" href="https://forms.gle/ZZ3cyKDeRvdTsg2q8" target="_blank"><div className="border border-info py-4 my-3">Билет 15</div></a>
                            <a className="p-3 m-3 font-weight-bold" href="https://forms.gle/HFFi6u5iFnfQ4Yw87" target="_blank"><div className="border border-info py-4 my-3">Билет 16</div></a>
                            <a className="p-3 m-3 font-weight-bold" href="https://forms.gle/bXhtMNAeSu7q8DNN7" target="_blank"><div className="border border-info py-4 my-3">Билет 17</div></a>
                            <a className="p-3 m-3 font-weight-bold" href="https://forms.gle/XfifsENfVwcxyMpJ6" target="_blank"><div className="border border-info py-4 my-3">Билет 18</div></a>
                            <a className="p-3 m-3 font-weight-bold" href="https://forms.gle/sTbkiNKFnYqWbP2B8" target="_blank"><div className="border border-info py-4 my-3">Билет 19</div></a>
                            <a className="p-3 m-3 font-weight-bold" href="https://forms.gle/wdcZyS3aFwsvRweD9" target="_blank"><div className="border border-info py-4 my-3">Билет 20</div></a>
                            <a className="p-3 m-3 font-weight-bold" href="https://forms.gle/PkhzbR6cULgSqSvT7" target="_blank"><div className="border border-info py-4 my-3">Билет 21</div></a>
                            <a className="p-3 m-3 font-weight-bold" href="https://forms.gle/CQyNGSPRwuis96kv9" target="_blank"><div className="border border-info py-4 my-3">Билет 22</div></a>
                            <a className="p-3 m-3 font-weight-bold" href="https://forms.gle/Kp89vMN8urTE63rq9" target="_blank"><div className="border border-info py-4 my-3">Билет 23</div></a>
                            <a className="p-3 m-3 font-weight-bold" href="https://forms.gle/gBptE9oGQsqexrVU8" target="_blank"><div className="border border-info py-4 my-3">Билет 24</div></a>
                            <a className="p-3 m-3 font-weight-bold" href="https://forms.gle/kpZDjrz5kA7y1Tst5" target="_blank"><div className="border border-info py-4 my-3">Билет 25</div></a>
                            <a className="p-3 m-3 font-weight-bold" href="https://forms.gle/tKheVn78uXZ2x5Hh8" target="_blank"><div className="border border-info py-4 my-3">Билет 26</div></a>
                            <a className="p-3 m-3 font-weight-bold" href="https://forms.gle/YRvoxutx8wPNc85d6" target="_blank"><div className="border border-info py-4 my-3">Билет 27</div></a>
                            <a className="p-3 m-3 font-weight-bold" href="https://forms.gle/t8tMCtojpmQj2YmG9" target="_blank"><div className="border border-info py-4 my-3">Билет 28</div></a>
                            <a className="p-3 m-3 font-weight-bold" href="https://forms.gle/MpddJg5Ww2TFKn2C6" target="_blank"><div className="border border-info py-4 my-3">Билет 29</div></a>
                            <a className="p-3 m-3 font-weight-bold" href="https://forms.gle/jiAFJ2mHqeizx19u9" target="_blank"><div className="border border-info py-4 my-3">Билет 30</div></a>
                            <a className="p-3 m-3 font-weight-bold" href="https://forms.gle/3DhHm2kANTiHVVW3A" target="_blank"><div className="border border-info py-4 my-3">Билет 31</div></a>
                            <a className="p-3 m-3 font-weight-bold" href="https://forms.gle/6x39SkS7vUVzSHxv7" target="_blank"><div className="border border-info py-4 my-3">Билет 32</div></a>
                            <a className="p-3 m-3 font-weight-bold" href="https://forms.gle/WASRPTGxR9oKcXvK9" target="_blank"><div className="border border-info py-4 my-3">Билет 33</div></a>
                            <a className="p-3 m-3 font-weight-bold" href="https://forms.gle/49224arcZM9Xb4d3A" target="_blank"><div className="border border-info py-4 my-3">Билет 34</div></a>
                            </div>
                            
                        </Container>

                    </Col>

                </Row>
            </Container>
        </section>
    </Layout>
)

export default PrepareGroupFourPage
